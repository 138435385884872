/* istanbul ignore file */
import qs from 'qs'
import { getUrl, getUrlV2, getAuthToken } from './utils'
import NetworkError from '@web/common/errors/NetworkError'
import { isOffline } from '@web/common/Utils'
import Locale from '@web/common/locale'
import localforage from 'localforage'
import { isOfflineEnable } from '@web/common/ExternalConfig/getters'
import { APP_ID, IS_MOBILE } from '@web/common/config'
import urlParse from 'url-parse'

export enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2',
}

export const processResponse = (response): Promise<Response> => {
  return new Promise((resolve, reject) => {
    // True if status is HTTP 2xx
    if (response.ok) {
      resolve(response)
    } else {
      if (response.status === 401) {
        document.dispatchEvent(new Event('logout'))
      }
      reject(new NetworkError(response))
    }
  })
}

const fetchInstance = (url, options = {} as RequestInit, apiVersion: ApiVersion): Promise<Response> => {
  options.headers = {
    ...options.headers,
    Authorization: getAuthToken(),
    'Accept-Language': Locale.get(),
    'X-App-Type': IS_MOBILE ? 'mobile' : 'web',
    'X-App-Id': APP_ID
  }

  let _url = getUrl(url)

  if (apiVersion === ApiVersion.v2) {
    _url = getUrlV2(url)
  }

  return fetch(_url, options)
    .then(processResponse)
}

export default class {
  static request (url, options = {}, apiVersion: ApiVersion = ApiVersion.v1) {
    return fetchInstance(url, options, apiVersion)
  }

  /**
   *
   * @param url
   * @param params
   * @param options
   * @returns {Promise<any>}
   */
  static async get (url, { params = {}, apiVersion = ApiVersion.v1, ...options } = {} as RequestInit & { params?: Dictionary<unknown>, apiVersion?: ApiVersion }): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    headers: Headers;
  }> {
    params.lang = Locale.get()

    url = url + '?' + qs.stringify(params, { encode: false })

    let fetchPromise: Promise<{ data: unknown; headers: Headers }>
    const CACHE_KEY = url
    if (isOffline() && isOfflineEnable()) {
      fetchPromise = localforage.getItem(CACHE_KEY).then((r: unknown) => {
        if (!r) {
          throw new Error('Cache not found')
        }

        if (!Object.prototype.hasOwnProperty.call(r, 'data')) {
          throw new Error('Invalid cache')
        }

        return r
      }) as Promise<{ data: unknown; headers: Headers }>
    } else {
      fetchPromise = fetchInstance(url, options, apiVersion)
        .then(async (response) => {
          const data = await response.json()

          if (isOfflineEnable() && IS_MOBILE) {
            localforage.setItem(CACHE_KEY, {
              // TODO: сделать сохранение headers
              // headers: response.headers,
              headers: {},
              data
            })
          }

          return {
            headers: response.headers,
            data
          }
        })
    }

    return fetchPromise
  }

  static post (url, data = {}, apiVersion: ApiVersion = ApiVersion.v1): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    headers: Headers;
  }> {
    // Добавления языка для переводов
    const urlInstance = urlParse(url, true)
    urlInstance.set('query', {
      lang: Locale.get(),
      ...urlInstance.query
    })

    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      // для cordova
      ':method': 'POST',
      body: JSON.stringify(data)
    }
    // потому что origin добавляется позже. см. api/utils/getUrl
    return fetchInstance(urlInstance.toString().replace(urlInstance.origin, ''), options, apiVersion)
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
  }

  static patch (url, data = {}, apiVersion: ApiVersion = ApiVersion.v1): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    headers: Headers;
  }> {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      // для cordova
      ':method': 'PATCH',
      body: JSON.stringify(data)
    }
    return fetchInstance(url, options, apiVersion)
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
  }

  static put (url, data = {}, apiVersion: ApiVersion = ApiVersion.v1): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    headers: Headers;
  }> {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      // для cordova
      ':method': 'PUT',
      body: JSON.stringify(data)
    }
    return fetchInstance(url, options, apiVersion)
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
  }

  static delete (url, apiVersion: ApiVersion = ApiVersion.v1): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    headers: Headers;
  }> {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      // для cordova
      ':method': 'DELETE'
    }
    return fetchInstance(url, options, apiVersion)
      .then(async (response) => {
        const data = {}

        return {
          data,
          headers: response.headers
        }
      })
  }
}
