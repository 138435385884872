import BannerApi from '@web/api/modules/Banner'
import Token from '@web/common/token'
import {
  BannerState,
  BannerGettersTree,
  BannerMutationsTree,
  BannerActionsTree
} from '@web/store/types/modules/banner'
import { isBannerEnable } from '@web/common/ExternalConfig/getters'
import { Platform, Position, InlinePosition } from '@web/mapper/BannerMapper'
import Logger from '@web/common/Logger'

const state: BannerState = {
  all: [],
  loading: false,
  newFeatures: {}
}
const filterBanners = (banner: Banner, positionId: number) => banner.positionsIds.includes(positionId)
const sort = (a, b) => a.sort - b.sort
const getters: BannerGettersTree = {
  banners: ({ all }) => isBannerEnable() ? all : [],
  bannerById: (state, { banners }) => id => banners.find(b => b.id === id),
  desktop: (state, { banners }) => banners.filter(banner => banner.platforms.includes(Platform.desktop)).sort(sort),
  desktopCourses: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.courses)).sort(sort),
  desktopCoursesTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCoursesTop)).sort(sort),
  desktopCoursesBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCoursesBottom)).sort(sort),
  desktopCoursesSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCoursesSidebar)).sort(sort),
  desktopCoursesBeforeCourse: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCoursesBeforeCourse)).sort(sort),
  desktopCalendar: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.calendar)).sort(sort),
  desktopCalendarTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCalendarTop)).sort(sort),
  desktopCalendarBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCalendarBottom)).sort(sort),
  desktopCalendarSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopCalendarSidebar)).sort(sort),
  desktopDay: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.day)).sort(sort),
  desktopDayTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopDayTop)).sort(sort),
  desktopDayBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopDayBottom)).sort(sort),
  desktopDayBeforeLessons: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopDayBeforeLessons)).sort(sort),
  desktopDayInsteadVideo: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopDayInsteadVideo)).sort(sort),
  desktopHomeworks: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.homeworks)).sort(sort),
  desktopHomeworksTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopHomeworksTop)).sort(sort),
  desktopHomeworksBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopHomeworksBottom)).sort(sort),
  desktopHomeworksSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopHomeworksSidebar)).sort(sort),
  desktopVideoconsultations: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.videoconsultations)).sort(sort),
  desktopVideoconsultationsTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopVideoconsultationsTop)).sort(sort),
  desktopVideoconsultationsBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopVideoconsultationsBottom)).sort(sort),
  desktopVideoconsultationsSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopVideoconsultationsSidebar)).sort(sort),
  desktopSupport: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.videoconsultations)).sort(sort),
  desktopSupportTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSupportTop)).sort(sort),
  desktopSupportBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSupportBottom)).sort(sort),
  desktopSupportSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSupportSidebar)).sort(sort),
  desktopSettings: (state, { desktop }) => desktop.filter(banner => banner.positions.includes(Position.videoconsultations)).sort(sort),
  desktopSettingsTop: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSettingsTop)).sort(sort),
  desktopSettingsBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSettingsBottom)).sort(sort),
  desktopSettingsSidebar: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.desktopSettingsSidebar)).sort(sort),
  mobile: (state, { banners }) => banners.filter(banner => banner.platforms.includes(Platform.mobile)).sort(sort),
  mobileCourses: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.courses)).sort(sort),
  mobileCoursesCatalog: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileCoursesCatalog)).sort(sort),
  mobileCalendar: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.calendar)).sort(sort),
  mobileCalendarBeforeStage: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileCalendarBeforeStage)).sort(sort),
  mobileDay: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.day)).sort(sort),
  mobileDayInsteadVideo: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileDayInsteadVideo)).sort(sort),
  mobileDayAfterVideo: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileDayAfterVideo)).sort(sort),
  mobileDayBottom: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileDayBottom)).sort(sort),
  mobileHomeworks: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.homeworks)).sort(sort),
  mobileHomeworksCatalog: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileHomeworksCatalog)).sort(sort),
  mobileVideoconsultations: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.videoconsultations)).sort(sort),
  mobileVideoconsultationsCatalog: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileVideoconsultationsCatalog)).sort(sort),
  mobileSupport: (state, { mobile }) => mobile.filter(banner => banner.positions.includes(Position.support)).sort(sort),
  mobileSupportCatalog: (state, { banners }) => banners.filter(banner => filterBanners(banner, InlinePosition.mobileSupportCatalog)).sort(sort),
  app: (state, { banners }) => banners.filter(banner => banner.platforms.includes(Platform.app)).sort(sort),
  appCourses: (state, { app }) => app.filter(banner => banner.positions.includes(Position.courses)).sort(sort),
  appDay: (state, { app }) => app.filter(banner => banner.positions.includes(Position.day)).sort(sort),
  appAll: (state, { app }) => app.filter(banner => banner.positions.includes(Position.all)).sort(sort),
  desktopMainPage: (state, { banners }) => state.newFeatures?.desktop ? [state.newFeatures.desktop] : banners.filter(banner => filterBanners(banner, InlinePosition.desktopMainPage)).sort(sort),
  mobileMainPage: (state, { banners }) => state.newFeatures?.mobile ? [state.newFeatures.mobile] : banners.filter(banner => filterBanners(banner, InlinePosition.mobileMainPage)).sort(sort)
}

const mutations: BannerMutationsTree = {
  setBanners (state, banners) {
    state.all = banners
  },
  setBannerNewFeatures (state, { position, banner }) {
    state.newFeatures[position] = banner
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  logout (state) {
    state.all = []
    state.newFeatures = {}
  }
}

/* istanbul ignore next */
const actions: BannerActionsTree = {
  async fetchBanners ({ commit, state, dispatch }) {
    if (!Token.get() || state.loading) {
      return Promise.resolve(false)
    }

    commit('setLoading', true)

    await dispatch('fetchBannersNewFeatures')

    return BannerApi.fetchAll()
      .then(({ data: banners }) => {
        commit('setBanners', banners)

        return Promise.resolve(true)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },

  async fetchBannersNewFeatures ({ dispatch }) {
    try {
      await dispatch('fetchBannerNewFeatures', 'desktop')
      await dispatch('fetchBannerNewFeatures', 'mobile')
    } catch (e) {
      Logger.error('fetchBannersNewFeatures error', e)
    }

    return Promise.resolve()
  },

  fetchBannerNewFeatures ({ commit }, position) {
    const positionNumber = position === 'desktop' ? InlinePosition.desktopMainPage : InlinePosition.mobileMainPage
    return BannerApi.fetchNewFeatures(positionNumber)
      .then(({ data: banner }) => {
        if (banner) {
          commit('setBannerNewFeatures', {
            position,
            banner
          })
        }

        return Promise.resolve(banner)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
