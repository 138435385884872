import SubscriptionMapper from '@web/mapper/SubscriptionMapper'
import { RawSubscription } from '@web/types/Subscription'
import client from '@web/api/client'

export default class SubscriptionApi {
  static fetchAll (): Promise<RawSubscription[]> {
    const params = {
      expand: 'cancelPopupText'
    }
    return client.get('/payment-subscription', {
      params
    })
      .then(response => {
        return response.data.map(item => SubscriptionMapper.toClient(item))
      })
  }

  static fetch (id: number): Promise<RawSubscription> {
    const params = {
      expand: 'cancelPopupText'
    }
    return client.get(`/payment-subscriptions/${id}`, {
      params
    })
      .then(response => {
        return SubscriptionMapper.toClient(response.data)
      })
  }

  static cancel ({ id, reasonType, reason }: { id: number, reasonType?: string, reason?: string }): Promise<{ subscription: RawSubscription, redirectUrl?: string | null }> {
    return client.post(`/payment-subscriptions/cancel?id=${id}`, {
      reason_type: reasonType || '',
      reason: reason || ''
    })
      .then(response => {
        return {
          subscription: SubscriptionMapper.toClient(response.data.subscription),
          redirectUrl: response.data.redirectUrl
        }
      })
  }
}
