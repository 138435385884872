import Analytics from './Analytics'
import { APP_ID } from '@web/common/config'
import localforage from 'localforage'
import Appsflyer from '@web/services/Analytics/modules/Appsflyer'
import Logger from '@web/common/Logger'

export function click (event: Dictionary<string>): void {
  Analytics.send({
    // eslint-disable-next-line camelcase
    element_type: 'other',
    ...event,
    action: 'click'
  })
}

export function openApp (): void {
  Analytics.send({
    action: 'open_app'
  })
}

export function courseDay ({ type }: { type: 'start' | 'finish' }): void {
  const action = `${type}_course_day` as 'start_course_day' | 'finish_course_day'
  Analytics.send({ action })
}

export function login (payload: { method: string; status: 'success' | 'fail'; userId?: number }): void {
  const event: EventBase = {
    action: 'login',
    method: payload.method,
    status: payload.status
  }
  if (payload.userId) {
    // eslint-disable-next-line camelcase
    event.user_id = payload.userId.toString()
  }
  Analytics.send(event)
}

export function registration (payload: { method: string }): void {
  Analytics.send({
    action: 'user_registration',
    method: payload.method
  })
}

export function addDemoCourse (payload: { serviceId: number; courseId: number }): void {
  Analytics.send({
    action: 'demo_course_add',
    // eslint-disable-next-line camelcase
    service_id: payload.serviceId.toString(),
    // eslint-disable-next-line camelcase
    course_id: payload.courseId.toString()
  })
}

export function logout (payload: { userId: number }): void {
  Analytics.send({
    action: 'logout',
    // eslint-disable-next-line camelcase
    user_id: payload.userId.toString()
  })
}

export function rating (payload: {
  type: 'expert' | 'consultation';
  targetId: string;
  userId: string;
  rating: string;
}): void {
  Analytics.send({
    action: `${payload.type}_rating`,
    target_id: payload.targetId,
    user_id: payload.userId,
    rating: payload.rating
  })
}

export function messageSend (): void {
  Analytics.send({ action: 'message_send' })
}

export function taskSend (payload: {
  homeworkId: string;
  homeworkType: string;
  homeworkStageId?: string;
  homeworkDayId?: string;
}): void {
  Analytics.send({
    action: 'task_send',
    homework_id: payload.homeworkId,
    homework_type: payload.homeworkType,
    ...(payload.homeworkStageId && { homework_stage_id: payload.homeworkStageId }),
    ...(payload.homeworkDayId && { homework_day_id: payload.homeworkDayId })
  })
}

export function filterChange (payload: {
  filterName: string;
  filterValue: string;
}): void {
  Analytics.send({
    action: 'filter_change',

    filter_name: payload.filterName,

    filter_value: payload.filterValue
  })
}

export function firstOpen (): void {
  const KEY = `first_open_${APP_ID}`
  localforage.getItem(KEY)
    .then(isOpen => {
      if (!isOpen) {
        Analytics.send({
          action: 'first_open'
        })
      }
      localforage.setItem(KEY, true)
    })
    .catch(() => {
      Analytics.send({
        action: 'first_open'
      })
    })
}

export function sendChatAnswer (payload: { name: string; type?: string; required?: boolean; pass?: boolean }): void {
  Analytics.send({
    action: 'chat_answer',
    name: payload.name,
    type: payload.type || 'text',
    required: payload.required ? 'true' : 'false',
    pass: payload.pass ? 'true' : 'false'
  })
}

export function firstOpenStage (): void {
  const KEY = `first_open_stage_${APP_ID}`
  localforage.getItem(KEY)
    .then(isOpen => {
      if (!isOpen) {
        Analytics.send({
          action: 'first_open_day'
        })
      }
      localforage.setItem(KEY, true)
    })
    .catch(() => {
      Analytics.send({
        action: 'first_open_day'
      })
    })
}

type ParamsCheckout = {
  serviceName: string;
  price: number;
  currency?: string;
  type: string;
  serviceId: number;
  quantity?: number;
}

export function inAppPurchase (): {
  beginCheckout: (paramsCheckout: ParamsCheckout) => void;
  purchase: (paramsCheckout: ParamsCheckout & { orderId: number }) => void;
  } {
  function beginCheckout ({ serviceName, price, currency = '', type, serviceId, quantity = 1 }: ParamsCheckout): void {
    Analytics.send({
      action: 'begin_checkout',
      price: String(price),
      // eslint-disable-next-line camelcase
      item_id: String(serviceId),
      // eslint-disable-next-line camelcase
      currency_code: currency,
      // eslint-disable-next-line camelcase
      payment_info_available: serviceName
    })

    Appsflyer.logEvent('af_initiated_checkout', {
      // eslint-disable-next-line camelcase
      af_content_id: String(serviceId),
      // eslint-disable-next-line camelcase
      af_content_type: type,
      // eslint-disable-next-line camelcase
      af_price: String(price),
      // eslint-disable-next-line camelcase
      af_currency: currency,
      // eslint-disable-next-line camelcase
      af_quantity: String(quantity),
      // eslint-disable-next-line camelcase
      af_payment_info_available: serviceName
    })
  }

  function purchase ({ serviceName, price, currency = '', type, serviceId, quantity = 1, orderId }: ParamsCheckout & { orderId: number }): void {
    Analytics.send({
      action: 'in_app_purchase',
      value: String(price),
      // eslint-disable-next-line camelcase
      item_category: type,
      // eslint-disable-next-line camelcase
      item_id: String(serviceId),
      // eslint-disable-next-line camelcase
      currency_code: currency,
      // eslint-disable-next-line camelcase
      item_name: serviceName,
      // eslint-disable-next-line camelcase
      order_id: String(orderId)
    })

    Appsflyer.logEvent('af_purchase', {
      // eslint-disable-next-line camelcase
      af_revenue: String(price),
      // eslint-disable-next-line camelcase
      af_content_id: String(serviceId),
      // eslint-disable-next-line camelcase
      af_content_type: type,
      // eslint-disable-next-line camelcase
      af_price: String(price),
      // eslint-disable-next-line camelcase
      af_quantity: String(quantity),
      // eslint-disable-next-line camelcase
      af_currency: currency,
      // eslint-disable-next-line camelcase
      af_content: serviceName,
      // eslint-disable-next-line camelcase
      af_order_id: String(orderId)
    })
  }

  return {
    beginCheckout,
    purchase
  }
}

export function videoInDay (): {
  init: (payload: PayloadVideoInDay) => void;
  start: () => void;
  complete: () => void;
  firstInDay: () => void;
  speed: (speed: number) => void;
  progress: (progress: number) => void;
  } {
  const VIDEO_PROGRESS_LIMITS: number[] = [75, 50, 25, 10]
  const event: InitVideoInDay = {} as InitVideoInDay

  let isVideoFirstInDay = false
  const videoProgresses: Dictionary<Dictionary<boolean>> = {}
  const videoCompleted: Dictionary<boolean> = {}

  return {
    init (payload: PayloadVideoInDay): void {
      // eslint-disable-next-line camelcase
      event.lesson_title = payload.lessonTitle
      // eslint-disable-next-line camelcase
      event.lesson_id = payload.lessonId
      // eslint-disable-next-line camelcase
      event.video_type = payload.videoType
      // eslint-disable-next-line camelcase
      event.video_orientation = payload.videoOrientation
    },
    start (): void {
      if (!event.lesson_id) {
        Logger.log('videoInDay not init. use videoInDay.init(payload: PayloadVideoInDay)')
        return
      }

      Analytics.send({
        ...event,
        action: 'video_start'
      })
    },
    complete (): void {
      if (!event.lesson_id) {
        Logger.log('videoInDay not init. use videoInDay.init(payload: PayloadVideoInDay)')
        return
      }

      if (!videoCompleted[event.lesson_id]) {
        Analytics.send({
          ...event,
          action: 'video_complete'
        })
        videoCompleted[event.lesson_id] = true
      }
    },
    firstInDay (): void {
      if (!event.lesson_id) {
        Logger.log('videoInDay not init. use videoInDay.init(payload: PayloadVideoInDay)')
        return
      }

      if (!isVideoFirstInDay) {
        Analytics.send({
          ...event,
          action: 'video_first_in_day'
        })

        isVideoFirstInDay = true
      }
    },
    speed (speed: number): void {
      if (!event.lesson_id) {
        Logger.log('videoInDay not init. use videoInDay.init(payload: PayloadVideoInDay)')
        return
      }

      Analytics.send({
        ...event,
        action: 'video_speed',
        speed: speed.toString()
      })
    },
    progress (progress: number): void {
      if (!event.lesson_id) {
        Logger.log('videoInDay not init. use videoInDay.init(payload: PayloadVideoInDay)')
        return
      }

      const videoKey = `${event.lesson_id}_${event.video_type}_${event.video_orientation}`
      let progressIsSet = false
      VIDEO_PROGRESS_LIMITS.forEach(l => {
        if (!videoProgresses?.[videoKey]?.[l] && l <= progress && !progressIsSet) {
          Analytics.send({
            ...event,
            action: 'video_progress',
            progress: l.toString()
          })

          if (!videoProgresses[videoKey]) {
            videoProgresses[videoKey] = {}
          }
          videoProgresses[videoKey][l] = true
          progressIsSet = true
        }
      })
    }
  }
}

export function courseDayProgress (): (params: { courseDayId: string; courseDayNumber: string; progress: number }) => void {
  const courseDayProgressDays: Dictionary<Dictionary<boolean>> = {}

  return function ({
    courseDayId,
    courseDayNumber,
    progress
  }: { courseDayId: string; courseDayNumber: string; progress: number }): void {
    const COURSE_DAY_PROGRESS_LIMIT: number[] = [15, 25, 50, 75, 90]
    const dayKey = `${courseDayId}_${courseDayNumber}`
    COURSE_DAY_PROGRESS_LIMIT.forEach(limit => {
      if (
        !courseDayProgressDays?.[dayKey]?.[limit] &&
        limit <= progress
      ) {
        Analytics.send({ action: 'course_day_progress', progress: limit.toString() })

        if (!courseDayProgressDays[dayKey]) {
          courseDayProgressDays[dayKey] = {}
        }
        courseDayProgressDays[dayKey][limit] = true
      }
    })
  }
}

export function showCommunityInPlatform (): void {
  const key = 'showCommunityInPlatform'
  if (sessionStorage.getItem(key)) {
    return
  }
  sessionStorage.setItem(key, '1')

  Analytics.send({
    action: 'articles_show_lk'
  })
}

let isScrolledCommunityInPlatform = false
export function scrollCommunityInPlatform (): void {
  if (isScrolledCommunityInPlatform) {
    return
  }
  isScrolledCommunityInPlatform = true

  Analytics.send({
    action: 'articles_preview_scroll'
  })
}

export function likeArticle ({ source, title }: { source: string, title: string }): void {
  Analytics.send({
    action: 'like',
    target_type: 'article',
    target_name: title,
    source
  })
}

export function dislikeArticle ({ source, title }: { source: string, title: string }): void {
  Analytics.send({
    action: 'like',
    target_type: 'article',
    target_name: title,
    source
  })
}

export function favouriteArticle ({ source, title }: { source: string, title: string }): void {
  Analytics.send({
    action: 'favourite',
    target_type: 'article',
    target_name: title,
    source
  })
}

export function unfavouriteArticle ({ source, title }: { source: string, title: string }): void {
  Analytics.send({
    action: 'unfavourite',
    target_type: 'article',
    target_name: title,
    source
  })
}

export function analyticsBannerShow ({ banner, position, url }: { banner: Banner, position: string, url: string }): void {
  Analytics.send({
    action: 'banner_show',
    target_type: 'banner',
    target_name: banner.title,
    target_id: banner.id.toString(),
    target_position: position,
    target_url: url,
    banner_title: banner.title,
    banner_id: banner.id.toString(),
    banner_position: position,
    banner_url: url,
    type: banner.type || '',
    banner_type: banner.type || '',
    analytic_product_name: banner.analyticProductName || '',
    analytic_school_title: banner.analyticSchoolTitle || ''
  })
}
export function analyticsBannerClick ({ banner, position, url }: { banner: Banner, position: string, url: string }): void {
  Analytics.send({
    action: 'banner_click',
    target_type: 'banner',
    target_name: banner.title,
    target_id: banner.id.toString(),
    target_position: position,
    target_url: url,
    banner_title: banner.title,
    banner_id: banner.id.toString(),
    banner_position: position,
    banner_url: url,
    type: banner.type || '',
    banner_type: banner.type || '',
    analytic_product_name: banner.analyticProductName || '',
    analytic_school_title: banner.analyticSchoolTitle || ''
  })
}
